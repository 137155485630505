import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Productos from './components/Productos';
import Aviso from './Pages/AvisoView/Aviso';
import Cuenta from './Pages/Cuenta/Cuenta';
import Tienda from './Pages/TiendaView/Tienda';
import Inicio from './Pages/Inicio/Inicio';
import TiendasDestacadas from './components/tiendas-destacadas/TiendasDestacadas';
import Begin from './Pages/Begin/Begin'; // Página de Login
import Contacto from './Pages/Contacto/Contacto';
import FloatingCard from './components/Floatings/FloatingCard';
import Navbar from './components/Navbar/Navbar';
import MostrarCookieSession from './components/Prueba';
import Precompra from './Pages/Precompra/Precompra';
import Cart from './Pages/Cart/Cart';
import { InfoCuentasProvider } from './Hooks/InfoCuentasContext'; // Importar el Provider de cuentas
import { FiltrosProductosProvider } from './Hooks/useFetchFiltrosProductos'; // Importar el nuevo Provider

const App = () => {
  const [showFloatingCard, setShowFloatingCard] = useState(false);

  const handleToggleClick = () => {
    setShowFloatingCard(!showFloatingCard);
  };

  return (
    <Router>
      {window.location.pathname !== '/login' && <Navbar onToggleClick={handleToggleClick} />}
      <Routes>
        <Route path="/login" element={<Begin />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/productos" element={
            <FiltrosProductosProvider>
              <Productos />
            </FiltrosProductosProvider>
          } />
          <Route path="/aviso/:id" element={<Aviso />} />
          <Route path="/tienda/:id" element={<Tienda />} />
          <Route path="/cuenta" element={<Cuenta />} />
          <Route path="/" element={<Inicio />} />
          <Route path="/tiendas" element={<TiendasDestacadas />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/previewbuy" element={<Precompra />} />
          <Route path="/tucarrito" element={<Cart />} />
          <Route path="/prueba" element={<MostrarCookieSession />} />
        </Route>
        
        {/* Ruta para redireccionar cualquier ruta no definida */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <FloatingCard
        className={showFloatingCard ? 'floating-card active' : 'floating-card'}
        onToggleClick={handleToggleClick}
      />
    </Router>
  );
};

// Componente para envolver rutas que requieren el Provider
const ProtectedRoutes = () => {
  return (
    <InfoCuentasProvider>
      <FiltrosProductosProvider>
        <Routes>
          <Route path="/productos" element={<Productos />} />
          <Route path="/aviso/:id" element={<Aviso />} />
          <Route path="/tienda/:id" element={<Tienda />} />
          <Route path="/cuenta" element={<Cuenta />} />
          <Route path="/" element={<Inicio />} />
          <Route path="/tiendas" element={<TiendasDestacadas />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/previewbuy" element={<Precompra />} />
          <Route path="/tucarrito" element={<Cart />} />
          <Route path="/prueba" element={<MostrarCookieSession />} />
        </Routes>
      </FiltrosProductosProvider>
    </InfoCuentasProvider>
  );
};


export default App;
