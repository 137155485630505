import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Importar useParams

const TiendaInfo = () => {
  const { id } = useParams(); // Obtener idtienda de la URL
  const [tienda, setTienda] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Efecto para obtener la información de la tienda
  useEffect(() => {
    const obtenerTienda = async () => {
      try {
        const response = await fetch(`/api/tienda/${id}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de la tienda');
        }
        const data = await response.json();
        setTienda(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    obtenerTienda();
  }, [id]);

  // Mientras carga
  if (loading) {
    return <p>Cargando...</p>;
  }

  // Si hubo un error
  if (error) {
    return <p>{error}</p>;
  }

  // Si no se encuentra la tienda
  if (!tienda) {
    return <p>Tienda no encontrada</p>;
  }

  // Renderizar los datos de la tienda
  return (
    <div>
      <h2>{tienda.Nombre}</h2>
      <p><strong>Dirección:</strong> {tienda.Direccion}</p>
      <p><strong>Ciudad:</strong> {tienda.Ciudad}</p>
      <p><strong>País:</strong> {tienda.Pais}</p>
      <p><strong>Teléfono:</strong> {tienda.Telefono}</p>
      <p><strong>Correo Electrónico:</strong> {tienda.CorreoElectronico}</p>
      <p><strong>Horario de Atención:</strong> {tienda.HorarioAtencion}</p>
      <p><strong>Descripción:</strong> {tienda.Descripcion}</p>
      <img src={tienda.Imagen} alt={tienda.Nombre} style={{ width: '300px', height: 'auto' }} />
    </div>
  );
};

export default TiendaInfo;
