import React, { useState, useEffect, useContext } from 'react';
import Carrousel from '../Carrousels/Carrousel';
import Loader from '../Loader/Loader';
import './AvisoContent.css'; // Asegúrate de importar el archivo CSS
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';

function AvisoContent({ id, idfiltro }) { // Añadir idfiltro como prop
  const [avisoInfo, setAvisoInfo] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [message, setMessage] = useState('');
  const [pathfiltro, setPathfiltro] = useState(''); // Estado para pathfiltro
  const { isAuthenticated } = useContext(InfoCuentasContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${window.location.origin}/api/infoavisos/${id}`;
        const response = await fetch(url);
        const data = await response.json();
  
        if (response.ok) {
          setAvisoInfo(data);
  
          // Asumiendo que data tiene un campo idfiltro
          const idFiltroAviso = data.Idfiltro;
          console.log("ID Filtro del Aviso:", idFiltroAviso);
  
          // Recuperar los filtros del localStorage
          const filtros = JSON.parse(localStorage.getItem('filtrosProductos'));
          console.log("Filtros en localStorage:", filtros);
  
          // Buscar el filtro original
          const filtroOriginal = filtros.find(filtro => filtro.idfiltro === idFiltroAviso);
  
          if (filtroOriginal) {
            // Buscar el filtro padre
            const filtroPadre = filtros.find(filtro => filtro.idfiltro === filtroOriginal.idfiltropadre);
  
            // Si tiene un filtro padre, concatenar los nombres
            if (filtroPadre) {
              const pathfiltro = `${filtroPadre.nombre} / ${filtroOriginal.nombre}`;
              setPathfiltro(pathfiltro);
              console.log("Nombre Completo:", pathfiltro);
            } else {
              console.log("Filtro Original sin filtro padre:", filtroOriginal.nombre);
            }
          } else {
            console.log("No se encontró un filtro con ese idfiltro.");
          }
        } else {
          throw new Error("La respuesta no es un JSON válido");
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };
  
    fetchData();
  }, [id]);  
  

  const restarUnidad = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };

  const sumarUnidad = () => {
    setCantidad(cantidad + 1);
  };

  const agregarAlCarrito = async () => {
    setShowOverlay(true); // Mostrar el overlay inmediatamente

    if (!isAuthenticated) {
      setMessage('Debes iniciar sesión para agregar productos al carrito');
      return;
    }

    try {
      const data = {
        action: "paste",
        avisos: [{
          avisoid: id,
          cant: cantidad,
          precio: avisoInfo.Precio
        }]
      };

      const response = await fetch('/api/carrito', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setShowButtons(true);
        setMessage('');
      } else {
        throw new Error('Error al agregar el producto al carrito');
      }
    } catch (error) {
      console.error("Error al agregar el producto al carrito:", error);
      setMessage(error.message);
    }
  };

  const handleOverlayClick = (action) => {
    setShowOverlay(false);
    if (action === 'ir') {
      window.location.href = '/tucarrito';
    } else if (action === 'seguir') {
      window.location.href = '/productos';
    }
  };

  return (
    <div id="aviso-container">
      <div className="maincenterbackground">
        <div className="indexcss-margin1">
          <div className="center-content-backgroundcolor">
            <div>
              {avisoInfo ? (
                <div className="halfsplit">
                  <div className="half50">
                    <p>{pathfiltro}</p> {/* Muestra el pathfiltro */} 
                    <p>Publicado por: {avisoInfo.Nombre}</p>
                    <h2>{avisoInfo.Titulo}</h2>
                    <p>{avisoInfo.Descripcion}</p>
                    <p>Precio: {avisoInfo.Precio}</p>
                    <p>Stock: {avisoInfo.Stockproducto}</p>
                    <p>Publicado el: {avisoInfo.Fechacreacion}</p>
                  </div>
                  <div className="half50">
                    <Carrousel imagenes={Object.values(avisoInfo).filter(value => value && typeof value === 'string' && value.startsWith('https://'))} />
                  </div>
                </div>
              ) : (
                <div>
                  <Loader />
                  <p>Cargando información del aviso</p>
                </div>
              )}
            </div>
          </div>
          <div className="indexcss-margin1">
            <div>
              <button onClick={restarUnidad}>-</button>
              <span>{cantidad}</span>
              <button onClick={sumarUnidad}>+</button>
            </div>
            <button className="comprar-button" onClick={() => alert("Comprar")}>Comprar</button>
            <button id="add-to-cart" onClick={agregarAlCarrito}>Agregar al carrito</button>
            {showOverlay && (
              <div className="overlay">
                <div className="overlay-content">
                  {message ? (
                    <>
                      <p>{message}</p>
                      <button onClick={() => window.location.href = '/login'}>Iniciar sesión</button>
                    </>
                  ) : (
                    <>
                      <p>Tu producto fue añadido al carrito!</p>
                      {showButtons && (
                        <div className="buttons">
                          <button onClick={() => handleOverlayClick('ir')}>Ir al carrito</button>
                          <button onClick={() => handleOverlayClick('seguir')}>Seguir comprando</button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvisoContent;
