import React from 'react';
import Postnav from '../../components/Postnav/Postnav';
import Footer from '../../components/Footer/Footer';
import PostCart from '../../components/Cart/PostCart';
import PersonalCard from '../../components/PersonalInfo/PersonalCard';

function Precompra() {

  const handleComprar = () => {
    console.log('Compra realizada');
    // Aquí puedes agregar la lógica para realizar la compra o redirigir a otra página.
  };

  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <PostCart />
            <PersonalCard />
            {/* Botón de compra debajo de PersonalCard */}
            <div className="compra-btn-container" style={{ textAlign: 'center', marginTop: '20px' }}>
              <button onClick={handleComprar} className="compra-btn">
                Ir a Pagar
              </button>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Precompra;
