import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import './d-Navbar.css';
import './m-Navbar.css';
import LogoWhite from './images/192.png';  // Importa la imagen
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBell } from '@fortawesome/free-solid-svg-icons'; 
import Toggle from '../toggle/Toggle';

function Navbar({ onToggleClick }) {
  const [isToggleActive, setIsToggleActive] = useState(false);

  const handleToggleClick = () => {
    setIsToggleActive(!isToggleActive);
    onToggleClick();
  };
  return (
    <div className="header-background-center-color">    
      <div className="nav-container">
        <div className="nav-center">
          <div className="nav-postcenter">
            <div className="d-nav-center-left">
              <img className="logowhite" src={LogoWhite} alt="Descripción de la imagen" />
            </div>
            <div className="m-nav-center-left">
            <a href="/">
              <img className="logoblack" src={LogoWhite} alt="Descripción de la imagen" />
            </a>
            </div>
            <div id="lupa" className="d-nav-center-center">
              <input type="text" id="searchInput" placeholder="Buscar..." />
            </div>
            <div className="d-nav-center-right">
              <nav>
                <ul className="nav-menu1">
                  <li><a href="/">Inicio</a></li>
                  <li><a href="/productos">Productos</a></li>
                  <li><a href="/contacto">Contacto</a></li>
                  <li><a href="/cuenta">Mi Cuenta</a></li>
                </ul>
              </nav>  
            </div>
            <div className="m-nav-center-right">
              <div className="navbar-cart-bell">
                <FontAwesomeIcon icon={faBell} />
                <Link to="/tucarrito" className="custom-link">
                    <FontAwesomeIcon icon={faShoppingCart} />
                </Link>
                <Toggle onToggle={handleToggleClick} />
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

export default Navbar;