import React, { useState, useContext } from 'react';
import './CrearTienda.css'; // Asegúrate de crear este archivo para estilos personalizados si lo necesitas
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';

const CrearTienda = () => {
  const { idcuenta, tiendas } = useContext(InfoCuentasContext); // Usa el contexto para obtener `idcuenta` y `tiendas`
  const [formData, setFormData] = useState({
    nombre: '',
    provincia: '',
    ciudad: '',
    direccion: '',
    telefono: '',
    mail: '',
    horario: '',
    descripcion: '',
    logo: '',
  });
  const [mensaje, setMensaje] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica si tienes `idcuenta` disponible
    if (!idcuenta) {
      setMensaje('Error: No se encontró la cuenta');
      return;
    }

    // Datos que serán enviados al endpoint
    const tiendaData = {
      Idcuenta: idcuenta,  // Lo obtenemos del contexto
      Nombre: formData.nombre,
      tiendadestacada: 0,  // Por defecto no destacada
      vencimiento: '2025-12-31',  // Cambia esto según necesites
      habilitada: 1  // Por defecto habilitada
    };

    try {
      const response = await fetch('/api/crear_tienda', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tiendaData),  // Enviamos los datos al servidor
      });

      if (response.ok) {
        const data = await response.json();
        setMensaje(`Tienda creada exitosamente con ID: ${data.Idtienda}`);
      } else {
        setMensaje('Error al crear la tienda');
      }
    } catch (error) {
      console.error('Error:', error);
      setMensaje('Ocurrió un error al enviar los datos');
    }

    // Limpia el formulario después de enviar los datos
    setFormData({
      nombre: '',
      provincia: '',
      ciudad: '',
      direccion: '',
      telefono: '',
      mail: '',
      horario: '',
      descripcion: '',
      logo: '',
    });
  };

  // Si el usuario ya tiene una tienda, mostrar un mensaje
  if (tiendas === 'si') {
    return <p>Ya tienes una tienda.</p>;
  }

  return (
    <div className="crear-tienda">
      <h2>Crear Tienda</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="provincia">Provincia:</label>
          <input
            type="text"
            id="provincia"
            name="provincia"
            value={formData.provincia}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ciudad">Ciudad:</label>
          <input
            type="text"
            id="ciudad"
            name="ciudad"
            value={formData.ciudad}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="direccion">Dirección:</label>
          <input
            type="text"
            id="direccion"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono:</label>
          <input
            type="text"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mail">Mail:</label>
          <input
            type="email"
            id="mail"
            name="mail"
            value={formData.mail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="horario">Horario:</label>
          <input
            type="text"
            id="horario"
            name="horario"
            value={formData.horario}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="descripcion">Descripción:</label>
          <textarea
            id="descripcion"
            name="descripcion"
            value={formData.descripcion}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="logo">Logo (URL):</label>
          <input
            type="text"
            id="logo"
            name="logo"
            value={formData.logo}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Crear Tienda</button>
      </form>
      {mensaje && <p className="mensaje">{mensaje}</p>}
    </div>
  );
};

export default CrearTienda;