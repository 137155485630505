import React from 'react';

function PersonalCard() {
  return (
    <div>
      <h2>Información Personal</h2>
      <p>Por ahora, acá va tu información personal.</p>
    </div>
  );
}

export default PersonalCard;
