import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const InfoCuentasContext = createContext();

export const InfoCuentasProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [idtienda, setIdTienda] = useState(''); 
  const [idcuenta, setIdCuenta] = useState(''); 
  const [tiendas, setTiendas] = useState(''); 
  const location = useLocation(); // Obtener la ubicación actual (URL)

  useEffect(() => {
    // Define las URLs en las que quieres que se ejecute el useEffect
    const allowedUrls = ['/', '/cuenta'];

    if (allowedUrls.includes(location.pathname)) {
      const fetchInfoCuentas = async () => {
        console.log('Fetching info de cuentas...');

        try {
          const response = await fetch('/api/infocuentas');
          console.log('Response Status:', response.status);

          if (response.status === 401) {
            setIsAuthenticated(false);
            console.log('Autenticación fallida');
          } else {
            const data = await response.json(); // Asumiendo que la respuesta es JSON

            // Recuperar datos del localStorage
            setIdCuenta(data.idcuenta); // Extrae y almacena el campo tiendas
            setTiendas(data.tiendas); // Extrae y almacena el campo tiendas
            setIdTienda(data.idtienda); // Extrae y almacena el campo tiendas
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
      fetchInfoCuentas();
    }
  }, [location.pathname]); // Se ejecutará cuando cambie la URL
  console.log(idcuenta, tiendas, idtienda);

  return (
    <InfoCuentasContext.Provider value={{ isAuthenticated,idcuenta,tiendas }}>
      {children}
    </InfoCuentasContext.Provider>
  );
};
