import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const FiltrosProductosContext = createContext();

export const FiltrosProductosProvider = ({ children }) => {
  const [filtros, setFiltros] = useState(null);
  const location = useLocation();
  
  useEffect(() => {
    console.log('URL actual:', location.pathname);
    
    if (location.pathname === '/productos') {
      console.log('useFetchFiltrosProductos disparado');
      
      const fetchFiltrosProductos = async () => {
        try {
          const response = await fetch('/api/productos/filtros');
          if (!response.ok) throw new Error('Error al obtener los filtros');
          const data = await response.json();
          localStorage.setItem('filtrosProductos', JSON.stringify(data));
          setFiltros(data);
        } catch (error) {
          console.error('Error fetching filtros:', error);
        }
      };
      
      fetchFiltrosProductos();
    }
  }, [location.pathname]);

  return (
    <FiltrosProductosContext.Provider value={{ filtros }}>
      {children}
    </FiltrosProductosContext.Provider>
  );
};
